import React from 'react';
import './index.css';
import Pdf from './Resume.pdf'

function Layout () {

    const onResumeClick = () => {
        window.open(Pdf);
      }

    return (
        <>
            <div class = "links-cont">
                <a href="https://www.linkedin.com/in/harris-hasnain" target="_blank" rel="noreferrer" class = "personal-link">
                    <b>LinkedIn &#127760;</b><br></br><span class = "link-desc">Connect with me!</span>
                </a>
                <a href="https://github.com/HarrisHasnain/project-collection.git" target="_blank" rel="noreferrer" class = "personal-link">
                    <b>GitHub Repo &#128187;</b><br></br><span class = "link-desc">Some of my projects</span>
                </a>
                <div onClick={onResumeClick} class = "personal-link">
                   <b> Resume &#128196;</b><br></br><span class = "link-desc">See more of my skills</span>
                </div>
                
            </div>
            <div class = "body-cont">
                <div class = "body-block-1">
                    <p class = "hello">Hi, I'm Harris &#128075;</p>
                    <p class = "desc">
                        I'm a 19 year old software engineering student at the University of Calgary, currently in my 3rd year of undergraduate studies
                        and <b><u>seeking internship opportunity!</u></b><br></br>
                        <br></br>My current passions as a developer are full-stack web development, embedded systems and machine learning.<br></br>
                        <br></br>Outside of software development and side projects,
                        my personal interests include soccer, basketball and personal fitness.<br></br>
                        <br></br>Click one of the links at the top to check out my LinkedIn, project repository or resume.
                    </p>
                </div>
   
                <div class = "body-block-3">
                    <b class = "contact-me"><u>Contact me:</u></b><br></br><br></br>
                    <div class = "links">
                        &#128222;&nbsp; -  <a href="tel:+1 (587) 889-1865" class = "contact-link">(587) 889-1865</a> 
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &#128233;&nbsp; - <a href = "mailto:harrishasnain12@gmail.com" class = "contact-link">harrishasnain12@gmail.com</a><br></br><br></br>
                    </div>
                </div>
            </div>
            <div class = "copyright-cont">
                &#169; Harris Hasnain 2023
            </div>
        </>
    );
}

export default Layout;